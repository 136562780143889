import { Component } from 'react'
import { API } from 'aws-amplify'
import TopMenu from '../components/topMenu'
import withRouter from '../components/withRouter'
import Carousel from 'react-bootstrap/Carousel'
import Logo from '../images/GigLoca_Logo_Light.png'
import './node.css'

class DYK extends Component {
    constructor(props) {
        super(props)

        this.state = {
            bounce: false,
            dyk: null
        }
    }

    componentDidMount() {
        if (this.props.isAuthenticated && this.props.user && this.props.user.active) {
            if (this.props.user && this.props.user.dyk && this.props.mode && this.props.user.dyk[this.props.mode.toLowerCase()]) {
                this.setState({ dyk: this.props.user.dyk[this.props.mode.toLowerCase()] })
            } //else setTimeout(() => this.handleNavigate(`my/${this.props.mode}s`), 150)
        } else {
            this.setState({ bounce: true, logo: false })
            setTimeout(() => this.handleNavigate('main', true), 3500)
        }
    }

    handleNavigate(page) {
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleOK() {
        const body = {
            type: this.state.dyk.type || null,
            dyk: this.state.dyk.id || null
        }
        if (body.type && body.dyk) {
            API.put('lt', 'dismiss', { body })
                .then(resp => {
                    API.get('lt', 'user')
                        .then(resp => {
                            this.props.setUser(resp.data)
                            this.props.onBack()
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            let user = this.props.user
            delete user.dyk
            this.props.setUser(user)
            this.props.onBack()
        }

    }

    getBounce() {
        return <div className='Node-Content'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
            <div className='Pref-Bounce'>For your All-Access Pass to all that Gigloca has to offer, create your account or sign in to Gigloca.</div>
        </div>
    }

    render() {
        console.log(this.state, this.props)
        return <div className='Node-Container Node-fan'>
            <TopMenu
                mode='fan'
                user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null} />
            {this.state.bounce ? this.getBounce() : <div className="DYK-Container">
                <div className="Node-Row Pref-Name">Did You Know?</div>
                <div className="Node-Row Node-Flex-Spread Node-Title" style={{ color: "#fff" }}>{this.state.dyk && this.state.dyk.title ? this.state.dyk.title : ''}</div>
                {this.state.dyk && this.state.dyk.img && this.state.dyk.img.length > 0 ? <div className='Node-Row'><Carousel className='Node-Carousel' controls={true} touch={true} indicators={true} interval={2000} fade>
                    {
                        this.state.dyk.img.map((ci, imgidx) => {
                            return <Carousel.Item key={`ci-${imgidx}`}>
                                <img src={`https://localtalent-upload.s3.amazonaws.com/public/${ci}`} className='Node-CI' alt={this.state.dyk.msg && this.state.dyk.msg[imgidx] ? this.state.dyk.msg[imgidx] : ''} />
                                {this.state.dyk.msg && this.state.dyk.msg[imgidx] && <Carousel.Caption><h2>{this.state.dyk.msg && this.state.dyk.msg[imgidx]}</h2></Carousel.Caption>}
                            </Carousel.Item>
                        })
                    }
                </Carousel></div> : null}
                <div className="Info-Flex-Wrap">
                    <div className='Info-Button Info-Button-Save' onClick={() => this.handleOK()}>Got It!</div>
                </div>
            </div>}
        </div>
    }
}

export default withRouter(DYK)