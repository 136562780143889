import { Component } from 'react'
import { API } from 'aws-amplify'
import moment from 'moment'
import Form from "react-bootstrap/Form"
import LoaderButton from '../components/loaderButton'
import Logo from '../images/GigLoca_Logo_Light.png'
import withRouter from '../components/withRouter'
import TopMenu from '../components/topMenu'
import BottomMenu from '../components/bottomMenu'
import Card from '../components/card'
import SmallCard from '../components/smallCard'
import QRCode from 'qrcode.react'
import Carousel from 'react-bootstrap/Carousel'
import { FaArrowLeft, FaMapMarkedAlt, FaRegQuestionCircle, FaQuestionCircle, FaChurch, FaStar, FaHeart, FaUser } from 'react-icons/fa'
import { HiOutlineBuildingStorefront } from 'react-icons/hi2'
import { LuCopy, LuCopyCheck, LuCalendarClock } from 'react-icons/lu'
import { BsCalendarFill, BsClockHistory, BsDiscFill, BsChatLeftDots, BsChatLeftHeart, BsInfoCircle } from 'react-icons/bs'
import { GiOldMicrophone, GiDrumKit, GiWineBottle } from 'react-icons/gi'
import { MdTheaterComedy, MdDeleteForever } from 'react-icons/md'
import { BiSolidMessageAdd, BiSolidMessageCheck, BiMessageAdd } from 'react-icons/bi'
import { IoRestaurantOutline } from 'react-icons/io5'
import { RiChatOffLine } from 'react-icons/ri'
import { TbFilterSearch } from 'react-icons/tb'
import { getAddress } from '../helpers'
import './node.css'

class Gigs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            bounce: false,
            displaySettings: false,
            displaySearch: false,
            loc: '',
            lastLoc: '',
            locIsGood: false,
            dist: 25,
            month: moment().date(1).format('YYYY-MM-DD'),
            days: [moment().date()],
            start: moment().format('YYYY-MM-DD'),
            end: moment().add(1, 'day').format('YYYY-MM-DD'),
            menu: {
                left: 'dashboard',
                right: null,
                mode: null,
                action: 'gigin'
            },
            data: null,
            view: 'list',
            more: null,
            id: null,
            node: '',
            cat: [],
            eid: null,
            event: null,
            vid: null,
            venue: null,
            copied: false,
            categories: null,
            displayConfirm: false,
            displayPost: false,
            toggle: 'events',
            all: false,
            confirmMsg: '',
            sidx: null,
            gigin: 'send',
            oneTime: false,
            displayMsg: false,
            msg: '',
            post: '',
            info: [
                { id: 0, displayInfo: true, displayMsg: false, msg: 'Clicking this option will notify the Venue that you are interested in participating in any future events.', dir: 'left', val: '43vw' }
            ]
        }

        this.handleOnPrefSelect = this.handleOnPrefSelect.bind(this)
        this.handleDisplaySettings = this.handleDisplaySettings.bind(this)
        this.handleModeChange = this.handleModeChange.bind(this)
        this.handleOnNotificationsSelect = this.handleOnNotificationsSelect.bind(this)
        this.handleGoToCard = this.handleGoToCard.bind(this)
        this.handleLocationCheck = this.handleLocationCheck.bind(this)
        this.handleLocChanged = this.handleLocChanged.bind(this)
        this.handleDistChanged = this.handleDistChanged.bind(this)
        this.handleDayChanged = this.handleDayChanged.bind(this)
        this.handleMonthChanged = this.handleMonthChanged.bind(this)
        this.handleMonthSelected = this.handleMonthSelected.bind(this)
        this.handleDisplaySearch = this.handleDisplaySearch.bind(this)
        this.handleCategoryChanged = this.handleCategoryChanged.bind(this)
        this.handleCategoryIsChecked = this.handleCategoryIsChecked.bind(this)
        this.handleViewEvent = this.handleViewEvent.bind(this)
        this.handleViewVenue = this.handleViewVenue.bind(this)
        this.handleLeftMenu = this.handleLeftMenu.bind(this)
        this.handleToggle = this.handleToggle.bind(this)
        this.handleAll = this.handleAll.bind(this)
        this.handleGetMyLoc = this.handleGetMyLoc.bind(this)
        this.handleGoToVenue = this.handleGoToVenue.bind(this)
    }

    componentDidMount() {
        if (this.props.isAuthenticated && this.props.user && this.props.user.active) {
            const { path, search } = this.props
            const id = path.id || null
            const queryParams = new URLSearchParams(search.search)
            const eid = queryParams.get('event') || null
            if (eid) {
                this.setState({ id, oneTime: true, eid })
                this.handleViewEvent(eid)
            } else if (id) {
                if (this.props.myloc) {
                    this.setState({ dist: this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist })
                    this.loadData(this.props.myLoc, id)
                } else {
                    const gl = navigator.geolocation
                    gl.getCurrentPosition(position => {
                        this.setState({ dist: this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist })
                        this.props.onMyLoc({ lat: position.coords.latitude, lng: position.coords.longitude })
                        this.loadData({ lat: position.coords.latitude, lng: position.coords.longitude }, id)
                    }, err => {
                        if (this.props.user && this.props.user.defaultLoc) {
                            this.setState({ dist: this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist })
                            this.props.onMyLoc(this.props.user.defaultLoc)
                            this.loadData(this.props.user.defaultLoc, id)
                        }
                    })
                }
            } else this.handleNavigate('my/contributors')
        } else {
            this.setState({ bounce: true })
            setTimeout(() => this.handleNavigate('main'), 3500)
        }
    }

    loadData(coord, id) {
        if (this.props.myNodes) {
            let node = null
            Object.keys(this.props.myNodes).forEach(key => this.props.myNodes[key].forEach(n => {
                if (n.id === id) {
                    node = n
                }
            }))
            if (node) {
                this.setState({ id, node, cat: [node.category] })
                this.getData(node.nodetype, [node.category], coord)
            } else {
                this.handleNavigate('my/contributors')
            }
        } else {
            API.get('lt', 'my')
                .then(resp => {
                    this.props.setMy(resp.data)
                    let node = null
                    Object.keys(resp.data).forEach(key => resp.data[key].forEach(n => {
                        if (n.id === id) {
                            node = n
                        }
                    }))
                    if (node) {
                        this.setState({ id, node, cat: [node.category] })
                        this.getData(node.nodetype, [node.category], coord)
                    } else {
                        this.handleNavigate('my/contributors')
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.handleNavigate('my/contributors')
                })
        }
    }

    handleNavigate(page, history) {
        if (history) this.props.onAddHistory(`gigs/${this.state.id}`)
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleBack() {
        if (this.state.oneTime) {
            this.handleNavigate(`my/contributor${this.state.id ? `/${this.state.id}` : 's'}`)
        } else if (this.state.view === 'list') {
            this.props.onBack()
        } else this.setState({ view: 'list', event: null, venue: null, gigin: 'send' })
    }

    handleDisplaySearch() {
        if (this.state.displaySearch) {
            this.getData()
            this.setState({ displaySettings: false, displaySearch: false })
        } else {
            if (!this.state.categories) {
                API.get('lt', 'options?prop=node')
                    .then(resp => {
                        let idx = resp.data.findIndex(d => d.ptype === this.state.node.nodetype)
                        if (idx > -1)
                            this.setState({ categories: resp.data[idx].categories.map(c => Object.keys(c)[0]) })
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                    })
            }
            this.setState({ displaySettings: false, displaySearch: true })
        }
    }

    handleLocationCheck() {
        if (this.state.loc && this.state.loc !== this.state.lastLoc) {
            API.get('lt', `geo?address=${this.state.loc.replace(/#/g, '%23')}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        const loc = resp.data.results[0].formatted_address
                        this.props.onMyLoc(resp.data.results[0].geometry.location)
                        this.setState({ loc, locIsGood: true, lastLoc: loc, center: resp.data.results[0].geometry.location, searchChanged: true })
                    }
                })
                .catch(e => {
                    this.setState({ loc: '', locIsGood: false })
                })
        }
    }

    handleGetMyLoc() {
        const gl = navigator.geolocation
        gl.getCurrentPosition(position => {
            API.get('lt', `geo?coord=${position.coords.latitude},${position.coords.longitude}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        const loc = resp.data.results[0].formatted_address
                        this.props.onMyLoc(resp.data.results[0].geometry.location)
                        this.setState({ loc, locIsGood: true, lastLoc: loc, center: resp.data.results[0].geometry.location, searchChanged: true })
                    } else {
                        this.setState({ locIsGood: false, loc: 'Not Found' })
                    }
                })
        }, err => {
            console.log(err)
        })
    }

    handleDistChanged(event) {
        let dist = event.target.value === 0 ? 1 : (event.target.value)
        this.setState({ dist })
    }

    handleLocChanged(event) {
        this.setState({ locIsGood: false, loc: event.target.value.replace(/[^a-zA-Z0-9-, ]/g, "") })
    }

    handleMonthSelected() {
        this.setState({ days: [], start: null, end: null })
    }

    handleMonthChanged(dir) {
        let month = moment(this.state.month)
        let days = []
        if (dir === 'back') {
            month.subtract(1, 'month')
        } else if (dir === 'next') {
            month.add(1, 'month')
        }
        let start = this.state.start ? moment(this.state.start) : null
        let end = this.state.end ? moment(this.state.end) : null
        if (start && end) {
            if (end.isBefore(month) || start.isAfter(moment(month).add(1, 'month'))) {
                days = []
            } else if (start.isBefore(month)) {
                if (end.isAfter(moment(month).add(1, 'month'))) {
                    for (let d = 1; d <= moment(month).add(1, 'month').subtract(1, 'day').date(); d++) {
                        days.push(d)
                    }
                } else if (end.isBetween(month, moment(month).add(1, 'month'))) {
                    for (let d = 1; d < end.date(); d++) {
                        days.push(d)
                    }
                }
            } else if (start.isSame(month) || start.isBetween(month, moment(month).add(1, 'month'))) {
                if (end.isAfter(moment(month).add(1, 'month'))) {
                    for (let d = start.date(); d <= moment(month).add(1, 'month').subtract(1, 'day').date(); d++) {
                        days.push(d)
                    }
                } else {
                    for (let d = start.date(); d < end.date(); d++) {
                        days.push(d)
                    }
                }
            }
        } else console.log('no days')
        this.setState({ days, month: month.format('YYYY-MM-DD'), start: start ? start.format('YYYY-MM-DD') : null, end: end ? end.format('YYYY-MM-DD') : null })
    }

    handleDayChanged(day) {
        if (this.state.start) {
            let month = moment(this.state.month)
            let sel = moment(this.state.month).date(day)
            let start = moment(this.state.start)
            let end = moment(this.state.end)
            let days = []
            if (sel.isBefore(end)) {
                start = sel
            } else {
                end = moment(sel).add(1, 'day')
            }
            if (start.isBefore(month)) {
                if (end.isAfter(moment(month).add(1, 'month'))) {
                    for (let d = 1; d <= moment(month).add(1, 'month').subtract(1, 'day').date(); d++) {
                        days.push(d)
                    }
                } else if (end.isBetween(month, moment(month).add(1, 'month'))) {
                    for (let d = 1; d < end.date(); d++) {
                        days.push(d)
                    }
                }
            } else if (start.isSame(month) || start.isBetween(month, moment(month).add(1, 'month'))) {
                if (end.isSame(moment(month).add(1, 'month')) || end.isAfter(moment(month).add(1, 'month'))) {
                    for (let d = start.date(); d <= moment(month).add(1, 'month').subtract(1, 'day').date(); d++) {
                        days.push(d)
                    }
                } else {
                    for (let d = start.date(); d < end.date(); d++) {
                        days.push(d)
                    }
                }
            }
            this.setState({ days, start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD'), searchChanged: true })
        } else this.setState({ start: moment(this.state.month).date(day).format('YYYY-MM-DD'), end: moment(this.state.month).date(day).add(1, 'day').format('YYYY-MM-DD'), days: [day], searchChanged: true })
    }

    handleToggle(toggle) {
        this.setState({ toggle })
    }

    handleAll() {
        this.setState({ all: !this.state.all })
    }

    handleOnPrefSelect() {
        this.handleNavigate('profile', true)
    }

    handleDisplaySettings() {
        this.setState({ displaySearch: false, displaySettings: !this.state.displaySettings })
    }
    
    handleModeChange(mode) {
        this.props.setMode(mode)
        if (mode === 'contactus') {
            this.handleNavigate('contactus', true)
        } else if (mode === 'fan') {
            this.handleNavigate('', true)
        } else if (mode === 'Venue') {
            this.props.setMode(mode)
            this.handleNavigate(`my/venues`)
        } else {
            this.setState({ displaySettings: false })
        }
    }

    handleOnNotificationsSelect() {
        this.handleNavigate('notifications', true)
    }

    handleLeftMenu() {
        this.handleNavigate('my/contributors')
    }

    handleGoToCard(nid, type) {
        if (nid && type) {
            this.handleNavigate(`my/${type === 'venue' ? 'venue' : 'contributor'}/${nid}`, true)
        }
    }

    handleGoToVenue(vid) {
        this.handleNavigate(`venue/${vid}`, true)
    }

    handleViewEvent(eid) {
        if (eid) {
            API.get('lt', `fanevent/${eid}`)
                .then(resp => {
                    if (resp.data.events && resp.data.events[0]) this.setState({ venue: { ...resp.data, events: null }, event: { ...resp.data.events[0] }, eid, view: 'event', vid: null })
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        }
    }

    handleViewVenue(vid) {
        if (vid) {
            API.get('lt', `fanstuff?nid=${vid}`)
                .then(resp => {
                    this.setState({ venue: resp.data[0] || null, view: 'venue', eid: null, vid })
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        }
    }

    handleCategoryChanged(c) {
        let cat = [...this.state.cat]
        let idx = cat.indexOf(c)
        if (idx > -1) {
            cat.splice(idx, 1)
        } else cat.push(c)
        this.setState({ cat })
    }

    handleCategoryIsChecked(c) {
        let idx = this.state.cat.indexOf(c)
        return idx > -1
    }

    handleCopy() {
        this.setState({ copied: true })
        navigator.clipboard.writeText(this.getAddress())
    }

    handleMore(more) {
        if (this.state.more === more) {
            this.setState({ more: null })
        } else this.setState({ more })
    }

    handleConfirm(confirm) {
        if (confirm) {
            this.handleInterest(this.state.sidx, confirm)
        } else this.setState({ displayConfirm: false, confirmMsg: '' })
    }

    handleInterest(sidx, confirm) {
        let idx = this.state.event.slots.findIndex(s => s.idx === sidx)
        if (idx > -1) {
            let slot = this.state.event.slots[idx]
            if (slot.selected && slot.selected === this.state.id) {
                if (confirm) {
                    this.doUnaccept(this.state.sidx)
                } else this.setState({ sidx, displayConfirm: true, confirmMsg: 'Are you sure you want to revoke acceptance?' })
            } else if (slot.interested && slot.interested.findIndex(i => i.id === this.state.id) > -1) {
                if (confirm) {
                    this.doInterest(this.state.sidx, 'remove')
                } else this.setState({ sidx, displayConfirm: true, confirmMsg: 'Are you sure you want to remove interest?' })
            } else {
                this.doInterest(sidx, 'add')
            }
        }
    }

    handleGigin(id) {
        if (this.state.gigin === 'send') {
            API.put('lt', `interest`, { body: { id: this.state.id, venue: id, local: moment().format('YYYY-MM-DD HH:mm') } })
                .then(resp => {
                    this.setState({ gigin: 'sent' })
                })
                .catch(err => {
                    this.setState({ gigin: 'failed' })
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        }
    }

    handleChange = event => {
        console.log(event.target.id)
        this.setState({ [event.target.id]: event.target.value.replace(/[<>]/g, "") })
    }

    handleAddPost() {
        this.setState({ displayPost: true })
    }

    handleCancelPost() {
        this.setState({ displayPost: false, post: '' })
    }

    handleSavePost = (event) => {
        event.preventDefault()
        console.log(this.state.post)
        if (this.state.post.length > 3) {
            this.setState({ isSaving: true })
            API.put('lt', `posts/event/${this.state.eid}`, { body: { post: this.state.post } })
                .then(resp => {

                    this.setState({ event: { ...this.state.event, posts: resp.event.posts || [] }, displayPost: false, post: '', isSaving: false })
                })
                .catch(e => {
                    this.setState({ displayPost: false, post: '', isSaving: false, msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
                    console.log(e)
                })
        }
    }

    handleDelPost(pid) {
        API.del('lt', `posts/event/${this.state.eid}/${pid}`)
            .then(resp => {
                this.setState({ event: { ...this.state.event, posts: resp.event.posts || [] } })
            })
            .catch(e => {
                console.log(e)
                this.setState({ msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
            })
    }

    handleMsgOK() {
        this.setState({ msg: '', displayMsg: false })
    }

    postIsValid() {
        return this.state.post.length > 3
    }

    handleInfo(id) {
        let info = [...this.state.info]
        info.splice(id, 1, { ...this.state.info[0], displayMsg: !this.state.info[id].displayMsg })
        this.setState({ info })
    }

    doInterest(sid, type) {
        API.put('lt', `interest/${this.state.event.id}`, { body: { id: this.state.id, slot: sid, local: moment().format('YYYY-MM-DD HH:mm') } })
            .then(resp => {
                let slots = [...this.state.event.slots]
                let idx = slots.findIndex(s => s.idx === sid)
                if (type === 'add') {
                    if (slots[idx].interested) {
                        slots[idx].interested.push({ id: this.state.id })
                    } else slots[idx].interested = [this.state.id]
                } else {
                    let nidx = slots[idx].interested.findIndex(i => i.id === this.state.id)
                    if (nidx > -1) slots[idx].interested.splice(nidx, 1)
                }
                this.setState({ event: { ...this.state.event, slots }, displayConfirm: false, confirmMsg: '', sidx: null })
            })
            .catch(e => {
                this.setState({ msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true, displayConfirm: false })
                console.log(e)
            })
    }

    doUnaccept(sid) {
        console.log('slot', sid, 'event', this.state.event.id, 'contributor', this.state.id)
        API.put('lt', `accept/${this.state.event.id}`, { body: { nid: this.state.id, slot: sid, local: moment().format('YYYY-MM-DD HH:mm') } })
            .then(resp => {
                let slots = [...this.state.event.slots]
                let nodes = [...this.state.event.nodes]
                let nidx = nodes.indexOf(this.state.id)
                if (nidx > -1) nodes.splice(nidx, 1)
                let idx = slots.findIndex(s => s.idx === sid)
                if (idx > -1) {
                    slots[idx].open = true
                    slots[idx].selected = null
                }
                this.setState({ event: { ...this.state.event, slots }, displayConfirm: false, confirmMsg: '', sidx: null })
            })
            .catch(e => {
                this.setState({ msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true, displayConfirm: false })
                console.log(e)
            })
    }

    getConfirm() {
        return <div className='Node-Confirm'>
            <div className='Node-Row Confirm-Text'>{this.state.confirmMsg}</div>
            <div className="Node-Row Node-Flex-Spread">
                <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleConfirm(true, 'interest')}>Yes</div>
                <div className='Pref-Button Pref-Button-Cancel' onClick={() => this.handleConfirm(false, 'interest')}>No</div>
            </div>
        </div>
    }

    getMessage() {
        return <div className='Node-Confirm'>
            <div className='Node-Row Confirm-Text'>{this.state.msg}</div>
            <div className="Node-Row Node-Flex-Wrap">
                <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleMsgOK()}>OK</div>
            </div>
        </div>
    }

    getData(node, cat, coord) {
        let qry = `lat=${coord ? coord.lat : this.props.myLoc.lat}&lng=${coord ? coord.lng : this.props.myLoc.lng}&dist=${this.state.dist}`
        if (this.state.toggle === 'events') {
            if (this.state.start && this.state.end) {
                qry += `&start=${this.state.start}&end=${this.state.end}`
            } else qry += `&start=${moment().format('YYYY-MM-DD HH:mm')}`
            API.get('lt', `open?node=${node || this.state.node.nodetype}&cat=${(cat || this.state.cat).toString()}${this.state.all ? `&all=${true}` : ''}${qry.length > 0 ? `&${qry}` : ''}`)
                .then(resp => {
                    this.setState({ data: resp.data })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ data: [] })
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        } else {
            API.get('lt', `nodes?node=Venue${qry.length > 0 ? `&${qry}` : ''}`)
                .then(resp => {
                    this.setState({ data: resp.data })
                })
                .catch(err => {
                    console.log(err)
                    this.setState({ data: [] })
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        }
    }

    getBGIcon() {
        if (this.state.toggle === 'events') {
            if (this.state.event && this.state.event.summary) {
                switch (this.state.event.summary) {
                    default:
                        return <HiOutlineBuildingStorefront />
                }
            } else return <FaRegQuestionCircle />
        } else {
            if (this.state.venue && this.state.venue.category) {
                switch (this.state.venue.category) {
                    case 'Restaurant':
                        return <IoRestaurantOutline />
                    case 'Bar':
                        return <GiWineBottle />
                    case 'Church':
                        return <FaChurch />
                    default:
                        return <HiOutlineBuildingStorefront />
                }
            } else return <FaRegQuestionCircle />
        }
    }

    getOpHours() {
        let days = []
        this.state.venue.operationArray.forEach(o => days = [...days, ...o.values.map(v => { return { open: o.start, close: o.stop, day: v } })])
        return days.map((d, dayidx) => {
            return <div key={`day-${dayidx}`} className='Node-Item'>{`${d.day} ${d.open} - ${d.close}`}</div>
        })
    }

    getCategoryIcon(cat) {
        switch (cat) {
            case "Karaoke":
                return <GiOldMicrophone />
            case "Live Music":
                return <GiDrumKit />
            case "Comedy":
                return <MdTheaterComedy />
            case "DJ":
                return <BsDiscFill />
            default:
                return <FaQuestionCircle />
        }
    }

    getBounce() {
        return <div className='Node-Content'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
            <div className='Pref-Bounce'>For your All-Access Pass to all that Gigloca has to offer, create your account or sign in to Gigloca.</div>
        </div>
    }

    getInfo(id) {
        return <div className='Info-Container' style={{ [this.state.info[id].dir]: this.state.info[0].val }}>
            <div className='Info-Row Info-Text'>{this.state.info[id].msg}</div>
            <div className="Info-Flex-Wrap">
                <div className='Info-Button Info-Button-Save' onClick={() => this.handleInfo(id)}>Got It!</div>
            </div>
        </div>
    }

    getNoResults() {
        return <div className='Node-NoResults-Container'>
            <div className='Node-NoResults-Icon'><TbFilterSearch /></div>
            <div className='Node-NoResults-Text'>No Matching Results</div>
            <div className='Node-NoResults-Text-2'>Refine your search criteria</div>
        </div>
    }

    getList() {
        if (this.state.toggle === 'events') {
            let data = []
            if (this.state.data) this.state.data.forEach(v => {
                if (v.events) v.events.forEach(e => data.push({ ...v, events: [e] }))
            })
            return <div className='Node-Cards'>{data.map((e, eidx) => {
                return <div key={`event-${eidx}`} style={{ margin: '5px 0' }}>
                    <Card type='event' data={e} near={this.props.user && this.props.user.pref && this.props.user.pref.distance && this.props.user.pref.distance >= (e.dist || 0)} onSelected={this.handleViewEvent} /></div>
            })}</div>
        } else {
            return this.state.data ? <div className='Node-Cards'>{this.state.data.map((v, vidx) => {
                return <div key={`venue-${vidx}`} style={{ margin: '5px 0' }}>
                    <Card type='venue' data={v} near={this.props.user && this.props.user.pref && this.props.user.pref.distance && this.props.user.pref.distance >= (v.dist || 0)} onSelected={this.handleViewVenue} /></div>
            })}</div> : null
        }
    }

    getEvent() {
        return this.state.event && <div className={window.innerWidth > 700 ? 'Node-Outer' : ''}>
            <div className='Node-Content-2'>
                {this.state.event.imageArray ? <div className='Node-Row'><Carousel className='Node-Carousel' controls={false} touch={true} indicators={true} interval={2000} fade>
                    {
                        this.state.event.imageArray.map((ci, imgidx) => {
                            return <Carousel.Item key={`ci-${imgidx}`}>
                                <img src={`https://localtalent-upload.s3.amazonaws.com/public/${ci}`} className='Node-CI' alt={this.state.event.name || this.state.event.title || ''} />
                            </Carousel.Item>
                        })
                    }
                </Carousel></div> : <div className='Node-Row Node-CI Node-BG-Icon'>{this.getBGIcon()}</div>}
                <div className='Node-Back'><div className='Node-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-13px' }}><FaArrowLeft /></div></div></div>
                <div className='Node-Content-3'>
                    {this.state.displayPost && <div className='Rate-Container'>
                        <form onSubmit={this.handleSavePost}>
                            <div className='Node-Row'><Form.Group controlId="post" className="form-group form-group-lg">
                                <Form.Label className='Pref-Label'>Post</Form.Label>
                                <Form.Control
                                    autoFocus
                                    placeholder="New Post"
                                    as="textarea"
                                    value={this.state.post}
                                    onChange={event => this.handleChange(event)}
                                />
                            </Form.Group></div>
                            <div className='Node-Row Node-Flex-Spread'>
                                <div style={{ width: '160px', height: '60px' }}><LoaderButton
                                    size="lg"
                                    disabled={!this.postIsValid()}
                                    type="submit"
                                    isLoading={this.state.isSaving}
                                    text='Post'
                                    loadingText='Saving...'
                                    className='Signup-Button'
                                    style={{ margin: '0px', bottom: '0' }}
                                /></div>
                                <div style={{ width: '160px', fontSize: '18pt', fontWeight: 'bold' }} className='Pref-Cancel-Button' onClick={() => this.handleCancelPost()}>Cancel</div>
                            </div>
                        </form>
                    </div>}
                    <div className='Node-Row Node-Flex-Spread'>
                        <div className='Node-Title'>{this.state.event.title ? this.state.event.title : 'Event'}</div>
                    </div>
                    <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Event Schedule</div>
                        <div className='Node-Divider'></div>
                    </div>
                    <div className='Node-Row Node-Flex-Spread'>
                        <div className='Node-Flex'>
                            <div className='Node-Addr-Icon'><BsCalendarFill /></div>
                            <div className='Pref-Text'>{this.state.event.startTime ? moment(this.state.event.startTime).format('MMM DD') : ''}</div>
                        </div>
                        <div className='Node-Flex'>
                            <div className='Node-Addr-Icon'><BsClockHistory /></div>
                            <div className='Pref-Text'>{this.state.event.startTime ? moment(this.state.event.startTime).format('h:mm A') : ''}</div>
                        </div>
                        <div className='Node-Flex'>
                            <div className='Node-Addr-Icon'><BsCalendarFill /></div>
                            <div className='Pref-Text'>{this.state.event.stopTime ? moment(this.state.event.stopTime).format('MMM DD') : ''}</div>
                        </div>
                        <div className='Node-Flex'>
                            <div className='Node-Addr-Icon'><BsClockHistory /></div>
                            <div className='Pref-Text'>{this.state.event.startTime ? moment(this.state.event.stopTime).format('h:mm A') : ''}</div>
                        </div>
                    </div>
                    <div className='Node-Row Node-Summary'>{this.state.venue.name || 'Venue'}</div>
                    <div className='Node-Row Node-Summary'>{this.state.event.summary || 'summary'}</div>
                    {this.state.venue.address && <div className='Node-Row Node-Flex'>
                        <div className='Node-Addr-Icon'><FaMapMarkedAlt /></div>
                        <div className='Node-Addr' onClick={() => this.handleCopy()}>
                            {this.state.venue.address ? getAddress(this.state.venue.address) : null}
                            <div className='Node-Addr-Icon'>{this.state.copied ? <LuCopyCheck /> : <LuCopy />}</div>
                        </div>
                    </div>}
                    {this.state.event.details && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Details</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.event.details && <div className='Node-Row'>
                        <div className='Node-Details'>{this.state.event.details}</div>
                    </div>}
                    {this.state.event.amenities && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Amenities</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.event.amenities && <div className='Node-Row Node-Flex-Wrap'>
                        {
                            this.state.event.amenities.map((am, amidx) => {
                                return <div key={`amenity-${amidx}`} className='Node-Item'>{am}</div>
                            })
                        }
                    </div>}
                    {this.state.event.restrictions && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Restrictions</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.event.restrictions && <div className='Node-Row Node-Flex-Left'>
                        {
                            this.state.event.restrictions.map((am, amidx) => {
                                return <div key={`amenity-${amidx}`} className='Node-Item'>{am}</div>
                            })
                        }
                    </div>}
                    <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Venue</div>
                        <div className='Node-Divider'></div>
                    </div>
                    <div className='Node-Row Node-Flex-Wrap'><Card data={this.state.venue} type='venue' onSelected={this.handleGoToVenue} /></div>
                    {this.state.event.slots &&
                        <div className='Node-Row Node-Flex-Left'>
                            <div className='Node-Divider-Text'>Slots</div>
                            <div className='Node-Divider'></div>
                        </div>}
                    {this.state.event.slots && <div className='Node-Row Node-Flex-Wrap'>
                        {this.state.event.slots.map((slot, sidx) => {
                            return slot.open ? <div key={`slot-${sidx}`} className={`My-Img-Container ${slot.node === this.state.node.nodetype && slot.category === this.state.node.category ? 'My-Img-Container-Perfect' : (slot.node === this.state.node.nodetype ? 'My-Img-Container-Good' : 'My-Img-Container-Bad')}`} onClick={() => this.handleInterest(slot.idx)}>
                                <div style={{ fontSize: '14pt' }} className='Node-Row-Thin'>{slot.node}</div>
                                <div className='Node-Row'>{this.getCategoryIcon(slot.category)}</div>
                                <div style={{ fontSize: '14pt' }} className='Node-Row-Thin'>{slot.category}</div>
                                <div className='Node-Row'>{slot.interested && slot.interested.findIndex(i => i.id === this.state.id) > -1 ? <BiSolidMessageCheck /> : <BiSolidMessageAdd />}</div>
                            </div> : <div key={`slot-${sidx}`}>{this.state.event.nodes && this.state.event.nodes.findIndex(n => n.id === slot.selected) > -1 ? <div onClick={() => this.handleInterest(slot.idx)}><SmallCard type='contributor' data={this.state.event.nodes.filter(n => n.id === slot.selected)[0]} /></div> : null}</div>
                        })}
                    </div>}
                    <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text Node-Flex' onClick={() => this.handleAddPost()}>Posts from {this.state.event.title} <div style={{ marginLeft: '5px' }}><BiMessageAdd /></div></div>
                        <div className='Node-Divider'></div>
                        {this.state.event.posts && this.state.event.posts.length > 0 && <div className='Node-Divider-Button' onClick={() => this.handleMore('posts')}>{this.state.more === 'posts' ? 'Less' : 'More'}</div>}
                    </div>
                    {this.state.event.posts && this.state.event.posts.length > 0 ? <div className={`My-Reviews-Container${this.state.more === 'posts' ? ' My-Reviews-More' : ''}`}>
                        {this.state.event.posts.sort((a, b) => {
                            if (moment(a.created).isAfter(moment(b.created))) return -1
                            if (moment(a.created).isBefore(moment(b.created))) return 1
                            return 0
                        }).map((post, pidx) => {
                            return <div key={`mpost-${pidx}`} className='My-Review-Container'>
                                <div className='Node-Row Node-Flex'>
                                    <div style={{ marginRight: '10px' }}>{post.image ? <img className='My-User-Image' src={`https://localtalent-upload.s3.amazonaws.com/public/${post.image}`} alt={'User'} /> : <div className='My-User-Image'><div style={{ marginTop: '-6px' }}><FaUser /></div></div>}</div>
                                    <div className='My-Review-Text'>{post.post}</div>
                                    <div>
                                        <div style={{ width: '75px', fontSize: '9pt' }} className='My-Review-Text'>{moment(post.created).format('ll')}</div>
                                        <div className='Post-Delete-Icon' onClick={() => this.handleDelPost(post.id)}><MdDeleteForever /></div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div> : null}
                    {this.state.id && <div className='Node-Row Node-Flex-Wrap'><QRCode value={`https://gigloca.com/contributor/${this.state.event.id}`} /></div>}
                </div>
            </div>
        </div>
    }

    getVenue() {
        return this.state.venue && <div className={window.innerWidth > 700 ? 'Node-Outer' : ''}>
            <div className='Node-Content-2'>
                {this.state.venue.imageArray ? <div className='Node-Row'><Carousel className='Node-Carousel' controls={false} touch={true} indicators={true} interval={2000} fade>
                    {
                        this.state.venue.imageArray.map((ci, imgidx) => {
                            return <Carousel.Item key={`ci-${imgidx}`}>
                                <img src={`https://localtalent-upload.s3.amazonaws.com/public/${ci}`} className='Node-CI' alt={this.state.venue.name || this.state.venue.title || ''} />
                            </Carousel.Item>
                        })
                    }
                </Carousel></div> : <div className='Node-Row Node-CI Node-BG-Icon'>{this.getBGIcon()}</div>}
                <div className='Node-Back'><div className='Node-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-13px' }}><FaArrowLeft /></div></div></div>
                <div className='Node-Content-3'>
                    <div className='Node-Row Node-Flex-Spread'>
                        <div className='Node-Title'>{this.state.venue.name ? this.state.venue.name : 'Venue'}</div>
                    </div>
                    <div className='Node-Row Node-Flex-Wrap'>
                        {
                            this.state.venue.genre && this.state.venue.genre.map((g, gidx) => {
                                return <div key={`genre-${gidx}`} className='Node-Item'>{g}</div>
                            })
                        }
                    </div>
                    {this.state.venue.address && <div className='Node-Row Node-Flex'>
                        <div className='Node-Addr-Icon'><FaMapMarkedAlt /></div>
                        <div className='Node-Addr' onClick={() => this.handleCopy()}>
                            {getAddress(this.state.venue.address)}
                            <div className='Node-Addr-Icon'>{this.state.copied ? <LuCopyCheck /> : <LuCopy />}</div>
                        </div>
                    </div>}
                    {this.state.data.operationArray && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Addr-Icon'><LuCalendarClock /></div>
                        <div className='Node-Divider-Text'>Hours of Operation</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.venue.operationArray && <div className='Node-Row Node-Flex-Left'>
                        {this.getOpHours()}
                    </div>}
                    {this.state.venue.description && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Description</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.venue.description && <div className='Node-Row'>
                        <div className='Node-Details'>{this.state.data.description}</div>
                    </div>}
                    {this.state.venue.amenities && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Amenities</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.venue.amenities && <div className='Node-Row Node-Flex-Left'>
                        {
                            this.state.venue.amenities.map((am, amidx) => {
                                return <div key={`amenity-${amidx}`} className='Node-Item'>{am}</div>
                            })
                        }
                    </div>}
                    {this.state.venue.restrictions && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Restrictions</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.venue.restrictions && <div className='Node-Row Node-Flex-Left'>
                        {
                            this.state.venue.restrictions.map((am, amidx) => {
                                return <div key={`amenity-${amidx}`} className='Node-Item'>{am}</div>
                            })
                        }
                    </div>}
                    {this.state.venue.stars && this.state.venue.stars > 0 ? <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Rating</div>
                        <div className='Node-Divider'></div>
                        <div style={{ marginRight: '5px' }}>
                            <div className='Node-Stars'><FaStar /></div>
                            <div className='Node-Stars-Text'>{this.state.venue.stars.toFixed(1)}</div>
                        </div>
                    </div> : null}
                    {this.state.venue.followers && this.state.venue.followers > 0 ? <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Followers</div>
                        <div className='Node-Divider'></div>
                        <div>
                            <div className='Node-Heart Node-Heart-Followers'><FaHeart /></div>
                            <div className='Node-Heart-Text'>{this.state.venue.followers > 1000 ? `${(this.state.venue.followers / 1000).toFixed(1)}k` : this.state.venue.followers}</div>
                        </div>
                    </div> : null}
                    {this.state.venue.events && this.state.venue.events.length > 0 && <div className='Node-Row Node-Flex-Left'>
                        <div className='Node-Divider-Text'>Upcoming Events</div>
                        <div className='Node-Divider'></div>
                    </div>}
                    {this.state.venue.events && this.state.venue.events.length > 0 && <div className='Node-Row Node-Flex-Wrap'>{this.state.venue.events.map((event, eidx) => {
                        return <SmallCard key={`ue-${eidx}`} type='event' data={event} onSelect={this.handleGoToCard} />
                    })}</div>}
                    <div className='Node-Row Node-Flex-Wrap'>
                        <div className='Node-Flex'>
                            <div className='Pref-Button Pref-Button-Save Node-Flex-Spread' style={{ width: '150px' }} onClick={() => this.handleGigin(this.state.venue.id)}><div>I'd GigIt</div>{this.state.gigin === 'sent' ? <BsChatLeftHeart /> : (this.state.gigin === 'send' ? <BsChatLeftDots /> : <RiChatOffLine />)}</div>
                            {this.state.info[0].displayInfo && this.state.gigin === 'send' && <div className='Info-Icon' onClick={() => this.handleInfo(0)}><BsInfoCircle /></div>}
                            {this.state.info[0].displayMsg && this.getInfo(0)}
                        </div>
                    </div>
                    {this.state.venue.id && <div className='Node-Row Node-Flex-Wrap'><QRCode value={`https://gigloca.com/contributor/${this.state.venue.id}`} /></div>}
                </div>
            </div>
        </div>
    }

    render() {
        //console.log(this.state, this.props)
        return <div className={`My-Container Node-Event`}>
            <TopMenu
                mode='Contributor'
                onMode={this.handleModeChange}
                onPref={this.handleOnPrefSelect}
                notifications={this.props.user ? (this.props.user.notifications ? this.props.user.notifications.length : true) : null}
                onNotifications={this.handleOnNotificationsSelect}
                user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null}
                onSettings={this.handleDisplaySettings}
                displaySearch={this.state.displaySearch}
                onSearch={this.handleDisplaySearch}
                displaySettings={this.state.displaySettings}
                loc={this.state.loc}
                locIsGood={this.state.locIsGood}
                onLocCheck={this.handleLocationCheck}
                onLocChanged={this.handleLocChanged}
                dist={this.state.dist}
                onDistChange={this.handleDistChanged}
                onGetMyLoc={this.handleGetMyLoc}
                month={this.state.month}
                days={this.state.days}
                onMonthChanged={this.handleMonthChanged}
                onMonthSelected={this.handleMonthSelected}
                onDaySelected={this.handleDayChanged}
                search={this.state.view === 'list'}
                range
                calendar
                categories={this.state.categories}
                categoryIsChecked={this.handleCategoryIsChecked}
                onCategoryChanged={this.handleCategoryChanged}
                toggle={this.state.toggle}
                onToggle={this.handleToggle}
                all={this.state.all}
                onAll={this.handleAll}
            />
            {
                this.state.bounce ? this.getBounce() : (this.state.view === 'event' ? this.getEvent() : (this.state.view === 'venue' ? this.getVenue() : (this.state.data && this.state.data.length === 0 ? this.getNoResults() : this.getList())))
            }
            {
                this.state.displayConfirm && this.getConfirm()
            }
            {
                this.state.displayMsg && this.getMessage()
            }
            <BottomMenu
                menu={this.state.menu}
                onLeft={this.handleLeftMenu}
                onRight={this.handleRightMenu} />
        </div>
    }
}

export default withRouter(Gigs)