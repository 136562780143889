import { Component } from 'react'
import { API, Storage } from 'aws-amplify'
import withRouter from '../components/withRouter'
import Form from "react-bootstrap/Form"
import LoaderButton from '../components/loaderButton'
import TopMenu from '../components/topMenu'
import BottomMenu from '../components/bottomMenu'
import { v1 as uuidv1 } from 'uuid'
import { MdImageNotSupported } from 'react-icons/md'
import { FcInvite } from 'react-icons/fc'
import { FaArrowLeft, FaLocationArrow, } from 'react-icons/fa'
import { FaFileCirclePlus, FaFileCircleCheck } from 'react-icons/fa6'
import { TbNotesOff } from 'react-icons/tb'
import { BsInfoCircle } from 'react-icons/bs'
import Checkmark from '../images/checkmark.svg'
import Logo from '../images/GigLoca_Logo_Light.png'
import './node.css'

class ContactUs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            menu: {
                left: 'home'
            },
            splash: true,
            doc: null,
            imageSize: false,
            isSaving: false,
            sent: false,
            categories: [],
            subject: '',
            description: '',
            loc: '',
            lastLoc: '',
            locIsGood: false,
            contact: '',
            displayMsg: false,
            msg: '',
            info: [
                { id: 0, displayInfo: false, displayMsg: false, msg: 'Click "Check Address" to validate and select the address.', dir: 'left', val: '60px' }
            ]
        }

        this.handleOnNotificationsSelect = this.handleOnNotificationsSelect.bind(this)
        this.handleLeftMenu = this.handleLeftMenu.bind(this)
    }

    componentDidMount() {
        const headers = this.props.user && this.props.user.active ? null : { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } }
        API.get('lt', `${this.props.user && this.props.user.active ? 'options' : 'contactoptions'}?prop=contactus`, headers)
            .then(resp => {
                let categories = []
                if (resp.data && resp.data[0]) {
                    categories = resp.data[0].categories
                }
                this.setState({ categories, splash: false })
            })
            .catch(err => {
                console.log(err)
                API.get('lt', `contactoptions?prop=contactus`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                    .then(resp => {
                        let categories = []
                        if (resp.data && resp.data[0]) {
                            categories = resp.data[0].categories
                        }
                        this.setState({ categories, splash: false })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            })
    }

    handleNavigate(page, history) {
        const { search } = this.props
        if (history) this.props.onAddHistory(search.pathname)
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleLeftMenu() {
        this.handleNavigate('', false)
    }

    handleBack() {
        this.props.onBack()
    }

    handleOnNotificationsSelect() {
        this.handleNavigate('notifications')
    }

    handleLocChanged(event) {
        let info = [...this.state.info]
        info.splice(0, 1, { ...this.state.info[0], displayInfo: event.target.value.length > 0, displayMsg: false })
        this.setState({ info, loc: event.target.value.replace(/[^a-zA-Z0-9- ]/g, ""), locIsGood: false })
    }

    handleGetMyLoc() {
        let info = [...this.state.info]
        info.splice(0, 1, { ...this.state.info[0], displayInfo: false, displayMsg: false })
        this.setState({ info })
        const gl = navigator.geolocation
        gl.getCurrentPosition(position => {
            API.get('lt', `geo?coord=${position.coords.latitude},${position.coords.longitude}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        const loc = resp.data.results[0].formatted_address
                        this.props.onMyLoc(resp.data.results[0].geometry.location)
                        this.setState({ loc, locIsGood: true, lastLoc: loc, center: resp.data.results[0].geometry.location, searchChanged: true })
                    } else {
                        this.setState({ locIsGood: false, loc: 'Not Found' })
                    }
                })
        }, err => {
            console.log(err)
        })
    }

    handleLocationCheck() {
        if (this.state.loc && this.state.loc !== this.state.lastLoc) {
            let info = [...this.state.info]
            info.splice(0, 1, { ...this.state.info[0], displayInfo: false, displayMsg: false })
            this.setState({ info })
            API.get('lt', `geo?address=${this.state.loc.replace(/#/g, '%23')}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        const loc = resp.data.results[0].formatted_address
                        this.props.onMyLoc(resp.data.results[0].geometry.location)
                        this.setState({ loc, locIsGood: true, lastLoc: loc, center: resp.data.results[0].geometry.location, searchChanged: true })
                    }
                })
                .catch(e => {
                    this.setState({ loc: '', locIsGood: false })
                })
        }
    }

    handleInfo(id) {
        let info = [...this.state.info]
        info.splice(id, 1, { ...this.state.info[0], displayMsg: !this.state.info[id].displayMsg })
        this.setState({ info })
    }

    handleChange = event => {
        switch (event.target.id) {
            case 'description':
                this.setState({ [event.target.id]: event.target.value.replace(/[<>]/g, "").substring(0, 999) })
                break
            case 'subject':
                this.setState({ [event.target.id]: event.target.value.replace(/[<>]/g, "").substring(0, 149) })
                break
            case 'contact':
                this.setState({ [event.target.id]: event.target.value.replace(/[<>]/g, "").substring(0, 49) })
                break
            default:
                this.setState({ [event.target.id]: event.target.value.replace(/[<>]/g, "") })
        }
    }

    handleDocChange(e) {
        const file = e.target.files[0]
        if (file.size < 5242880) {
            if (file.type.indexOf('image/') > -1) {
                let doc = uuidv1() + file.type.replace('image/', '.')
                this.setState({ doc: { file, doc, docUrl: URL.createObjectURL(file) } })
            } else if (file.type.indexOf('application/') > -1) {
                let doc = uuidv1() + file.type.replace('application/', '.')
                this.setState({ doc: { file, doc, docUrl: URL.createObjectURL(file) } })
            } else this.setState({ badDoc: true })
        } else this.setState({ imageSize: 'doc', badDoc: false })
        this.fileInput.value = ''
    }

    handleClearDoc() {
        this.setState({ doc: null, imageSize: false })
    }

    handleSubmit = event => {
        event.preventDefault()
        if (this.formIsValid()) {
            this.setState({ isSaving: true })
            const body = {
                type: 'contactus',
                category: this.state.category,
                subject: this.state.subject,
                description: this.state.description,
                location: this.state.loc,
                documentation: this.state.doc && this.state.doc.doc ? this.state.doc.doc : null,
                contact: this.state.contact
            }
            if (this.state.doc && this.state.doc.file) Storage.put(`${this.state.doc.doc}`, this.state.doc.file, {
                progressCallback(progress) { console.log(`Uploaded: ${progress.loaded}/${progress.total}`) }
            })
            const headers = this.props.isAuthenticated ? null : { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` }
            API.post('lt', `${this.props.isAuthenticated ? 'invite' : 'contactus'}`, { body, headers })
                .then(resp => {
                    this.setState({ isSaving: false, sent: true })
                    setTimeout(() => this.handleBack(), 3000)
                })
                .catch(e => {
                    API.post('lt', `contactus`, { body, headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                        .then(resp => {
                            this.setState({ isSaving: false, sent: true })
                            setTimeout(() => this.handleBack(), 3000)
                        })
                        .catch(e => {
                            this.setState({ isSaving: false, msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
                            console.log(e)
                        })
                })
        }
    }

    handleMsgOK() {
        this.setState({ msg: '', displayMsg: false })
    }

    handleCancel() {
        this.props.onBack()
    }

    handleOnPrefSelect() {
        this.handleNavigate('profile')
    }

    formIsValid() {
        return this.state.category !== 'Select...' &&
            this.state.subject.length > 3
    }

    getValidation(field) {
        switch (field) {
            case 'subject':
                return this.state[field].length > 3
            case 'description':
                return this.state[field].length > 3
            case 'contact':
                return this.state[field].length > 5
            default:
                return false
        }
    }

    getSplash() {
        return <div className='My-Splash'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
        </div>
    }

    getMessage() {
        return <div className='Node-Confirm'>
            <div className='Node-Row Confirm-Text'>{this.state.msg}</div>
            <div className="Node-Row Node-Flex-Wrap">
                <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleMsgOK()}>OK</div>
            </div>
        </div>
    }

    getInfo(id) {
        return <div className='Info-Container' style={{ [this.state.info[id].dir]: this.state.info[0].val }}>
            <div className='Info-Row Info-Text'>{this.state.info[id].msg}</div>
            <div className="Info-Flex-Wrap">
                <div className='Info-Button Info-Button-Save' onClick={() => this.handleInfo(id)}>Got It!</div>
            </div>
        </div>
    }

    getForm() {
        return <div className={window.innerWidth > 700 ? 'Node-Outer' : ''}>
            <div className='Pref-Back Node-Flex-Spread'><div className='Node-Arrow' onClick={() => this.handleBack()}><div style={{ marginTop: '-13px' }}><FaArrowLeft /></div></div><div className='Contact-Header'>Contact Us</div></div>
            <div className='Node-Row Node-Form'>
                <form onSubmit={this.handleSubmit}>
                    <div className='Node-Row'>
                        <Form.Label className='Pref-Label'>Category *</Form.Label>
                        <Form.Select style={{ color: '#190753', borderRadius: '5px', fontSize: '14pt', width: '100%', marginBottom: '5px', padding: '5px' }} value={this.state.category} id='category' onChange={this.handleChange}>
                            <option value=''>Select...</option>
                            {this.state.categories.map((cat, cidx) => {
                                return <option key={`cat-${cidx}`} value={cat}>{cat}</option>
                            })}
                        </Form.Select>
                    </div>
                    <div className='Node-Row'>
                        <Form.Group controlId="subject" className="form-group form-group-lg">
                            <Form.Label className='Pref-Label'>Subject *</Form.Label>
                            <Form.Control
                                placeholder="Subject"
                                type="text"
                                value={this.state.subject}
                                onChange={this.handleChange}
                                isValid={this.getValidation('subject')}
                                isInvalid={!this.getValidation('subject')}
                            />
                        </Form.Group>
                    </div>
                    <div className='Node-Row'>
                        <Form.Group controlId="description" className="form-group form-group-lg">
                            <Form.Label className='Pref-Label'>{this.state.category} Description *</Form.Label>
                            <Form.Control
                                placeholder="Description"
                                as="textarea"
                                value={this.state.description}
                                rows={8}
                                onChange={this.handleChange}
                                isValid={this.getValidation('description')}
                                isInvalid={!this.getValidation('description')}
                            />
                        </Form.Group>
                    </div>
                    <div className='Node-Row'>
                        <Form.Group controlId="loc" className="form-group-lg">
                            <Form.Label className='Pref-Label'>Location (if applicable)<span style={{ marginLeft: '8px' }} onClick={() => this.handleGetMyLoc()}><FaLocationArrow /></span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Search Location"
                                value={this.state.loc}
                                onChange={event => this.handleLocChanged(event)}
                                isValid={this.state.locIsGood}
                                isInvalid={!this.state.locIsGood}
                            />
                        </Form.Group>
                        <div className='Node-Row'>
                            <div className='Node-Flex'>
                                <div className='Search-Label Search-Link' onClick={() => this.handleLocationCheck(this.state.loc)}>Look Up{this.props.locIsGood && <span style={{ marginLeft: '5px' }}><img src={Checkmark} alt="Verified" className='Search-Location-Checkmark' /></span>}</div>
                                {this.state.info[0].displayInfo && <div className='Info-Icon' onClick={() => this.handleInfo(0)}><BsInfoCircle /></div>}
                                {this.state.info[0].displayMsg && this.getInfo(0)}
                            </div>
                        </div>
                    </div>
                    <div className='Node-Row'>
                        <Form.Group className="form-group form-group-lg">
                            <Form.Label className='Pref-Label'>{this.state.category} Supporting Documentation</Form.Label>
                        </Form.Group>
                        {this.props.isAuthenticated ? <div className='Node-Row Node-Flex-Wrap'>
                            <div className='My-Img-Container'>
                                {
                                    this.state.doc ? (this.state.doc.file.type.indexOf('image/') > -1 ? <img onClick={() => this.handleClearDoc()} className='My-Img-Container' style={{ border: 'none', width: '169px', height: '244px' }} src={this.state.doc.docUrl} alt='' /> :
                                        <div style={{ paddingTop: '60px', color: 'green' }} onClick={() => this.handleClearDoc()}><FaFileCircleCheck /></div>) :
                                        <label>
                                            <input style={{ display: 'none' }} type="file" onChange={event => this.handleDocChange(event)} ref={ref => this.fileInput = ref} />
                                            <div style={{ paddingTop: '60px' }}>{this.state.imageSize === 'doc' ? <span><MdImageNotSupported />Size</span> : (this.state.badDoc ? <span><TbNotesOff />Type</span> : <FaFileCirclePlus />)}</div>
                                        </label>
                                }
                            </div>
                        </div> : <div style={{ width: '100%', textAlign: 'center', textDecoration: 'italics', fontWeight: 'bold', color: '#fff' }}>Sign In to include an Image</div>}
                    </div>
                    <div className='Node-Row'>
                        <Form.Group controlId="contact" className="form-group form-group-lg">
                            <Form.Label className='Pref-Label'>Contact Me</Form.Label>
                            <Form.Control
                                placeholder="Email or Phone"
                                type="email"
                                value={this.state.contact}
                                onChange={this.handleChange}
                                isValid={this.getValidation('contact')}
                                isInvalid={!this.getValidation('contact')}
                            />
                        </Form.Group>
                    </div>
                    {
                        this.state.sent && <div className='Node-Row Node-Flex-Wrap'>
                            <div className='Contact-Sent'><FcInvite /></div>
                            <div className='Node-Row Contact-Sent-Text'>We take your feedback seriously and will give your message the attention is deserves.</div>
                        </div>
                    }
                    <div className='Node-Row'>
                        <LoaderButton
                            size="lg"
                            disabled={!this.formIsValid()}
                            type="submit"
                            isLoading={this.state.isSaving}
                            text={this.state.nid ? 'Update' : 'Submit'}
                            loadingText={this.state.nid ? 'Updating...' : 'Submitting...'}
                            className='Signup-Button'
                        />
                        <div className='Node-Row Pref-Cancel-Button' onClick={() => this.handleCancel()}>Cancel</div>
                    </div>
                </form>
            </div>
        </div>
    }

    render() {
        return <div>
            <div className={`My-Container Node-fan`}>
                <TopMenu
                    mode='contactus'
                    onPref={this.handleOnPrefSelect}
                    notifications={this.props.user ? (this.props.user.notifications && this.props.user.notifications.length > 0 ? this.props.user.notifications.length : true) : null}
                    onNotifications={this.handleOnNotificationsSelect}
                    user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null}
                    pref={null} />
                {
                    this.state.splash ? this.getSplash() : this.getForm()
                }
                {
                    this.state.displayMsg && this.getMessage()
                }
                <BottomMenu
                    menu={this.state.menu}
                    onLeft={this.handleLeftMenu}
                />
            </div>
        </div>
    }
}

export default withRouter(ContactUs)