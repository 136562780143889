import React from "react"
import { Route, Routes } from "react-router-dom"
import NotFound from './containers/notFound'
import Login from './containers/login'
import Main from './containers/main'
import Signup from './containers/signup'
import CardList from "./containers/cardList"
import Giglit from "./containers/giglit"
import Venue from "./containers/venue"
import Event from "./containers/event"
import Contributor from "./containers/contributor"
import Legal from './containers/legal'
import Pref from './containers/pref'
import Fan from './containers/fan'
import MyNodes from "./containers/myNodes"
import MyNode from "./containers/myNode"
import MyEvent from "./containers/myEvent"
import Gigs from './containers/gigs'
import Notifications from './containers/notifications'
import ContactUs from './containers/contact'
import Tix from './containers/tix'
import TixCheck from './containers/tixcheck'
import DYK from './containers/dyk'

export default function Links({ childProps }) {
  return (
    <Routes>
      <Route path="/" element={<Fan {...childProps} />} />
      <Route path="/login" element={<Login {...childProps} />} />
      <Route path="/signup" element={<Signup {...childProps} />} />
      <Route path="/list" element={<CardList {...childProps} />} />
      <Route path="/giglit" element={<Giglit  {...childProps} />} />
      <Route path="/profile" element={<Pref {...childProps} />} />
      <Route path="/notifications" element={<Notifications {...childProps} />} />
      <Route path="/my/venues" element={<MyNodes {...childProps} />} />
      <Route path="/my/contributors" element={<MyNodes {...childProps} />} />
      <Route path="/my/venue/:id" element={<MyNode {...childProps} />} />
      <Route path="/my/contributor/:id" element={<MyNode {...childProps} />} />
      <Route path="/my/event/:id" element={<MyEvent {...childProps} />} />
      <Route path="/gigs/:id" element={<Gigs  {...childProps} />} />
      <Route path="/venue/:id" element={<Venue  {...childProps} />} />
      <Route path="/event/:id" element={<Event  {...childProps} />} />
      <Route path="/contributor/:id" element={<Contributor  {...childProps} />} />
      <Route path="/tix/:id" element={<Tix  {...childProps} />} />
      <Route path="/tixcheck" element={<TixCheck  {...childProps} />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/main" element={<Main />} />
      <Route path="/contactus" element={<ContactUs {...childProps} />} />
      <Route path="/dyk" element={<DYK {...childProps} />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}